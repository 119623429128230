@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

:root {
  --dark-color: rgb(20, 20, 20);
  --yellow-color: rgb(253, 216, 53);
  --blue-color: rgb(98, 0, 234);
  --pink-color: #e83283;
  --cyan-color: #39cbfb;
  --blue-color: #3a8fd9;

  --c1-color: rgb(38, 70, 83);
  --c2-color: rgb(42, 157, 143);

  --left-color: var(--pink-color);
  --right-color: var(--blue-color);
}

.banner-feeling {
  position: relative;
  background-color: var(--dark-color);
  /* left: -100px; */
  width: 100%;
  height: 85vh;
}

.banner-feeling .side {
  display: grid;
  height: 85vh;
  overflow: hidden;
  place-items: center;
  position: absolute;
  width: 100%;
}

.banner-feeling .side .title {
  font-family: 'Rubik', sans-serif;
  font-size: 6em;
  margin: 0px 10vw;
  width: 80vw;
  /* original 80vw */
}

.banner-feeling .side .fancy {
  font-family: 'Lobster', cursive;
  font-size: 1.2em;
  line-height: 0.8em;
}

.banner-feeling #left-side {
  background-color: var(--left-color);
  width: 60%;
  z-index: 2;
}

.banner-feeling #left-side .title {
  color: white;
}

.banner-feeling #left-side .fancy {
  color: var(--yellow-color);
}

.banner-feeling #right-side {
  background-color: var(--right-color);
}

.banner-feeling #right-side .title {
  color: var(--dark-color);
}

.banner-feeling #right-side .fancy {
  color: white;
}
