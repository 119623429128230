:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

/* html * {
  font-family: 'Roboto', sans-serif;
} */

/* #root {
  background: linear-gradient(-45deg, #8263a1 0%, #75a7c3 100%);
} */

main {
  min-height: 80vh;
  cursor: default;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background: #343a40;
  color: #f4f4f4;
  padding: 20px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.rating {
  margin: 0.1rem;
}

/* carousel */
/* .carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
} */

/* .carousel-caption {
  position: absolute;
  top: 0;
} */

.carousel-caption h2 {
  color: #fff;
  background-color: black;
}

/* .carousel img {
  height: 500px;
} */

/* .carousel a {
  margin: 0 auto;
} */

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.alert-light {
  background: var(--light-color);
  color: #333;
}

.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.alert-success {
  background: var(--success-color);
  color: #fff;
}

.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.alert .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.videoWrapper {
  position: relative;
  /* padding-bottom: 56.25%; */
  height: 0;
  overflow: hidden;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tags-input ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.tags-input ul li {
  margin: 0 0.5rem 0 0;
}

/* SOCIAL STUFF */
.social-container {
  text-align: center;
}

.social:hover {
  transform: translateY(-2px);
}

.youtube {
  color: #eb3223;
}

.facebook {
  color: #4968ad;
}

.twitter {
  color: #49a1eb;
}

.instagram {
  color: black;
}

.social {
  margin: 0 0.75rem;
  transition: transform 250ms;
  display: inline-block;
  color: rgb(220, 220, 220);
}

@media (max-width: 500px) {
  .announcement {
    display: none;
  }

  .sub-menu .sub-menu-item {
    margin: 0px 3px !important;
    transform: scale(0.5);
  }

  .sub-menu .dropdown-toggle {
    margin: 0px 3px !important;
    /* width: 30px !important;
    height: 30px !important; */
  }

  .checkout-popup-card .checkout-popup-button {
    right: 0px !important;
  }

  .storeavatar {
    top: -160px;
  }
}

@media (min-width: 500px) {
  /* Make dropdown menu auto on hover */
  /* .nav-item:hover .dropdown-menu {
    display: block !important;
  } */

  .checkout-popup-card {
    top: 0px !important;
  }
}

@media (max-width: 768px) {
  .program-screen-image-ref1 {
    display: block;
  }

  .program-screen-image-ref2 {
    display: none;
  }

  .banksy-shredder .left-side {
    justify-content: center !important;
  }

  /* .banksy-shredder .banner-text {
    display: none !important;
  } */

  .checkout-popup-card-vending {
    width: 320px !important;
  }

  .checkout-popup-card .connect-wallet {
    text-align: left !important;
  }

  .checkout-popup-card {
    width: 320px !important;
    right: 0px !important;
  }

  .checkout-popup-card .list-group {
    width: 320px !important;
  }

  .checkout-popup-card img {
    width: 60px !important;
  }

  .shoe-customizer {
    width: 90% !important;
  }

  .shoe-customizer .upload-texture-box {
    bottom: 10px !important;
    left: 10px !important;
    top: auto !important;
    right: auto !important;
  }

  .shoe-customizer h1 {
    margin: 0px 15px !important;
    top: 10px !important;
    left: 130px !important;
    font-size: 2em !important;
    letter-spacing: 0px !important;
  }

  .shoe-customizer .picker {
    top: 10px !important;
    left: 20px !important;
    width: 100px !important;
    height: 100px !important;
  }

  .shoe-customizer .color-picker-input {
    top: 116px !important;
    left: -15px !important;
  }

  .connect-wallet-desktop {
    display: none;
  }

  .header-search-box {
    width: 100% !important;
  }

  .connect-wallet-button-v2 {
    width: 120px !important;
  }
}

@media (min-width: 768px) {
  .program-screen-image-ref1 {
    display: none;
  }

  .program-screen-image-ref2 {
    display: block;
  }

  .connect-wallet-mobile {
    display: none;
  }
}

#defaultCanvas0 {
  width: 500px;
}
