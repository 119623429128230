/* body {
  overflow: hidden;
  background: linear-gradient(to bottom, #f1f4f9, #dff1ff);
} */

.background-light {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(#f1f4f9, #dff1ff);
  z-index: -1;
}

.background-light .color {
  position: absolute;
  filter: blur(50px);
}

.background-light .color:nth-child(1) {
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: #ff359b;
}

.background-light .color:nth-child(2) {
  top: 15%;
  bottom: 15%;
  right: 35%;
  width: 35%;
  height: 55%;
  background: #fffd87;
}

.background-light .color:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 40%;
  height: 85%;
  background: #00d2ff;
}
