// More themes: https://github.com/ThemesGuide/bootstrap-themes

@import 'variables';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'bootswatch';

// body {
//   font-family: 'Roboto', sans-serif;
// }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.SfxMenuItem-wrapper {
  color: rgba(118, 129, 132, 1);
}

.connect-wallet-button {
  height: 36px !important;
  min-width: 150px !important;
  border: none !important;
}

#switch-network optgroup:nth-child(2) option:nth-child(n + 3) {
  display: none;
}

#switch-network optgroup:nth-child(2) option:nth-child(7) {
  display: block;
}

#switch-network optgroup:nth-child(3) option:nth-child(n + 2) {
  display: none;
}

#switch-network optgroup:nth-child(4) {
  display: none;
}

reach-portal {
  font-size: 0.9rem;
}

// Product card outline effect
// .product-card::after {
//   content: '';
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   width: 500px;
//   height: 500px;
//   background-image: conic-gradient(
//     transparent,
//     transparent,
//     transparent,
//     #d400d4
//   );
//   animation: product-card-animation 4s linear infinite;
//   animation-delay: -2s;
// }

// .product-card::before {
//   content: '';
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   width: 500px;
//   height: 500px;
//   background-image: conic-gradient(
//     transparent,
//     transparent,
//     transparent,
//     #00ccff
//   );
//   animation: product-card-animation 4s linear infinite;
// }

// @keyframes product-card-animation {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// .product-card .product-span {
//   position: absolute;
//   inset: -5px;
//   border-radius: 8px;
//   background: #0c1022;
//   z-index: 1;
// }
.product-card {
  transition: all 0.2s ease-in-out;
}

.product-card:hover {
  transform: scale(1.03);
}

.form-check-input {
  cursor: pointer;
}

// .photo-frame-effect + img {
//   border: solid 2px;
//   border-bottom-color: #ffe;
//   border-left-color: #eed;
//   border-right-color: #eed;
//   border-top-color: #ccb;
//   max-height: 100%;
//   max-width: 100%;
// }

.photo-frame-effect {
  background-color: #ddc;
  border: solid 5vmin #eee;
  border-bottom-color: #fff;
  border-left-color: #eee;
  border-radius: 2px;
  border-right-color: #eee;
  border-top-color: #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
    0 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: inline-block;
  // margin: 10vh 10vw;
  // padding: 8vmin;
  height: 80vh;
  position: relative;
  // text-align: center;

  &:before {
    border-radius: 2px;
    bottom: -2vmin;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25) inset;
    content: '';
    left: -2vmin;
    position: absolute;
    right: -2vmin;
    top: -2vmin;
  }
  // &:after {
  //   border-radius: 2px;
  //   bottom: -2.5vmin;
  //   box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  //   content: '';
  //   left: -2.5vmin;
  //   position: absolute;
  //   right: -2.5vmin;
  //   top: -2.5vmin;
  // }
}
